
.flex-column {
    display: flex;
    flex-direction: column;
  }

.whole-invite {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-top: 8vh;
  max-width: 400px;
}

.whole-invite {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-top: 8vh;
  max-width: 400px;
}

.btn {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin: 2px 2px 2px 2px;
}

.shadow-guest-input {
  background: rgba(171, 205, 239, 0.3)
}