nav{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 5vh;
  color: white;
}

.nav-links{
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Rotate-up {
  transform: rotate(270deg);
}

.App-link {
  color: #61dafb;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.flex-container-right {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-basis: 25.7%;
}

.whole {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-evenly;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.6);
  z-index: -5;
  border-radius: 10px;
  min-width: 320px;
  max-width: 600px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Row-left {
  display: flex;
  position: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-basis: 8.3%;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.Left-column {
  display: flex;
  flex-direction: column;
}

.Flex-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 50%;
}

.Barcode {
  height: auto;
  position: 2;
  width: 200px;
  transform: rotate(270deg);
  flex: 1 0 25%;
}

.Left-svg {
  height: 800px;
  position: 2;
  width: 1200px;
  object-fit: fill;
  flex: 1 0 75%;
}

.Left-title {
  height: auto;
  width: auto;
  transform: rotate(270deg);
  flex: 1 0 25%;
}

.Left-subtitle {
  height: auto;
  width: auto;
  transform: rotate(270deg);
  flex: 2 0 25%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Search-invitation-button {
  display: inline-block;
  padding: 8px;
  border-radius: 6px;
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 100px;
  text-decoration-color: white;
  text-decoration: none;
  color: white;
  text-align: center;
  box-shadow: '0 3px 5px 2px rgba(255, 105, 135, .3)';
  border: 0;
  text-decoration-line: none;
}

.Search-invitation-button-active {
  text-decoration: none;
}

.Search-invitation {
  display: inline-block;
  padding: 8px 55px;
  border-radius: 6px;
  border-color: #FE4066;
  outline-color: #FE4066;
  height: 50px;
  width: 220px;
  text-decoration: rgb(47, 47, 129);
  color: white;
}

input:focus{
  border: red;
}


.Qrcode-hide {
  display: none;
}


.Title-location {
  height: 35%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
  font-style: sans-serif;
  font-weight: 600;
  color: rgb(4, 45, 148);
}

.Title-intro {
  height: 40%;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
  font-style: sans-serif;
  font-family: Arial;
  color: rgb(225, 0, 97);
}

.Title-rsvp {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 40px;
  font-style: sans-serif;
  font-family: Arial;
  color: #FE4066;
  text-align: center;
}

.Google-rsvp {
  height: 40%;
  width: 40%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 40px;
  font-style: sans-serif;
  font-family: Arial;
  color: #FE4066;
}

.Acceptance-guests {
  font-size: 20px;
  font-style: sans-serif;
  font-family: Arial;
  color: rgb(221, 118, 181);
}

.gift {
  height: 30px;
  margin-bottom: 2px;
}

.Textfields-guests {
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 400;
  min-width: 200;
  width: '70vw';
  position: 'relative';
  font-size: 16px;
  padding: '10px 12px',
/*   min-width: 50vw;
  max-width: 500px; */
}

.Guest-lists {
  list-style: none;
}